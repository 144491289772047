import React, { useEffect, useState, useRef } from "react";
import Timer from "./Timer";

const EMOM = ({ minutes }) => {
  const [currentMinute, setCurrentMinute] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    const startTimer = () => {
      if (currentMinute < minutes && !isPaused) {
        timerRef.current = setTimeout(() => {
          setCurrentMinute(currentMinute + 1);
        }, 60000); // 60 seconds
      }
    };

    startTimer();

    return () => {
      // Limpiar el temporizador si el componente se desmonta
      clearTimeout(timerRef.current);
    };
  }, [currentMinute, minutes, isPaused]);

  const togglePause = () => {
    setIsPaused(!isPaused);
    // Limpiar el temporizador si se pausa
    if (isPaused) {
      clearTimeout(timerRef.current);
    }
  };

  return (
    <div id="emom">
      <h3>EMOM</h3>
      {currentMinute < minutes && (
        <div className="timer_emom">
          <div>
            {currentMinute} / {minutes}
          </div>
          <Timer isPaused={isPaused} type="emom" duration={60} key={currentMinute} /> 
        </div>
      )}
      <button onClick={togglePause}>{isPaused ? "Resume" : "Pause"}</button>
    </div>
  );
};

export default EMOM;
