import React, { useState, useEffect } from "react";

const Timer = ({ isPaused, type, duration, onTimerComplete }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (duration === undefined) {
      setTimeLeft(60);
    }

    if (timeLeft === 0) {
      if (onTimerComplete) {
        onTimerComplete();
      }
      return;
    }

    let timerId;

    if (!isPaused) {
      timerId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [timeLeft, duration, onTimerComplete, isPaused]);

  return (
    <div className="counter">
      {duration
        ? `${
            type === "emom"
              ? timeLeft < 10
                ? `00:0${timeLeft}`
                : `00:${timeLeft}`
              : timeLeft < 10
              ? `0${timeLeft}`
              : timeLeft
          }`
        : "Indefinido"}
    </div>
  );
};

export default Timer;
