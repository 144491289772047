import React, { useState, useEffect } from "react";
import EMOM from "./EMOM";
import AMRAP from "./AMRAP";
import TimedWorkout from "./TimedWorkout";
import Exercise from "./Exercise";

const WorkoutDay = ({ exercises }) => {
  const [activeWorkout, setActiveWorkout] = useState(null);
  const [minutes, setMinutes] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedWorkoutType, setSelectedWorkoutType] = useState(null);
  const [countdown, setCountdown] = useState(5); // Inicialmente 5 segundos de cuenta regresiva
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [exerciseCompleted, setExerciseCompleted] = useState(false);

  const handleStartWorkout = (workoutType) => {
    setSelectedWorkoutType(workoutType);
    setShowModal(true);
  };

  const handleCancelWorkout = () => {
    window.location.reload();
  };

  const handleModalConfirm = () => {
    // Iniciar la cuenta regresiva de 5 segundos
    let count = 5;
    const countdownInterval = setInterval(() => {
      if (count > 0) {
        setCountdown(count);
        count--;
      } else {
        clearInterval(countdownInterval);
        // Mostrar el contenido después de la cuenta regresiva
        setActiveWorkout(selectedWorkoutType);
        setShowModal(false);
      }
    }, 1000); // Intervalo de 1 segundo
  };

  const handleNextExercise = () => {
    const nextIndex = currentExerciseIndex + 1;
    if (nextIndex < exercises[selectedWorkoutType].length) {
      setCurrentExerciseIndex(nextIndex);
    } else {
      // Finaliza el entrenamiento si ya no hay más ejercicios
      setActiveWorkout(null);
      setCurrentExerciseIndex(0); // Opcional: reiniciar el índice para un nuevo comienzo
    }
  };

  useEffect(() => {
    // Reiniciar el contador cuando se muestra el modal
    if (showModal) {
      setCountdown(5);
    }
  }, [showModal]);

  return (
    <>
      <section id="Timer">
        {showModal && (
          <div id="modal_minutes">
            <p>¿Cuántos minutos para el entrenamiento?</p>
            <input
              type="number"
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
            />
            <p>Comenzando en: {countdown} segundos</p>
            <button onClick={handleModalConfirm}>Confirmar</button>
            <button onClick={() => setShowModal(false)}>Cancelar</button>
          </div>
        )}

        {activeWorkout === null && (
          <button onClick={() => handleStartWorkout("emom")}>
            Iniciar EMOM
          </button>
        )}
        {activeWorkout === null && (
          <button onClick={() => handleStartWorkout("amrap")}>
            Iniciar AMRAP
          </button>
        )}
        {activeWorkout === null && (
          <button onClick={() => handleStartWorkout("timed")}>
            Iniciar Por Tiempo
          </button>
        )}

        {activeWorkout !== null && (
          <button onClick={handleCancelWorkout}>
            Usar otro tipo de cronometro
          </button>
        )}

        {activeWorkout !== null && activeWorkout === "emom" && (
          <EMOM minutes={minutes} />
        )}
        {activeWorkout !== null && activeWorkout === "amrap" && (
          <AMRAP minutes={minutes} />
        )}
        {activeWorkout !== null && activeWorkout === "timed" && (
          <TimedWorkout minutes={minutes} />
        )}
      </section>
      <section id="exercises">
        {exercises !== null &&
        selectedWorkoutType !== null &&
        exercises[selectedWorkoutType] !== undefined &&
        activeWorkout !== null
          ? exercises[selectedWorkoutType].map((exercise, index) => (
              <Exercise
                index={index + 1}
                name={exercise.name}
                duration={exercise.duration || null}
                repetitions={exercise.repetitions || null}
                onNextExercise={handleNextExercise}
                onComplete={() => setExerciseCompleted(true)}
                isActive={index === currentExerciseIndex}
                isCompleted={index < currentExerciseIndex}
              />
            ))
          : activeWorkout !== null && <p>No tienes ejercicios</p>}
      </section>
    </>
  );
};

export default WorkoutDay;
