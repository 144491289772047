import React, { useState, useEffect } from "react";
import Timer from "./Timer";

const Exercise = ({
    index,
  name,
  duration,
  repetitions,
  onNextExercise,
  onComplete,
  isActive,
  isCompleted,
}) => {
  const [exerciseComplete, setExerciseComplete] = useState(isCompleted);

  useEffect(() => {
    if (duration && isActive && !exerciseComplete) {
      const timer = setTimeout(() => {
        setExerciseComplete(true);
        onComplete && onComplete();
      }, duration * 1000);
      return () => clearTimeout(timer);
    }
  }, [duration, isActive, exerciseComplete, onComplete]);

  useEffect(() => {
    if (exerciseComplete && onNextExercise && isActive) {
      onNextExercise();
    }
  }, [exerciseComplete, onNextExercise, isActive]);

  const getDurationExercise = () => {
    if (!exerciseComplete && isActive) {
      return <Timer type="exercise" duration={duration} />;
    }
    return <p>{duration}</p>;
  };

  return (
    <div className={`exercise ${exerciseComplete ? "completed" : ""}`}>
      <table>
        <thead>
          <tr>
            <th>Ejercicio</th>
            <th>Nombre</th>
            <th>{duration ? "Duración" : "Repeticiones"}</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{index}</td>
            <td>{name}</td>
            <td>{duration ? getDurationExercise() : repetitions}</td>
            <td>
              {!exerciseComplete && isActive && (
                <button onClick={() => setExerciseComplete(true)}>
                  Completar
                </button>
              )}
              {exerciseComplete && (
                <button style={{ background: "grey" }} disabled>
                  Completado
                </button>
              )}
              {!exerciseComplete && !isActive && (
                <button style={{ background: "grey" }} disabled>
                  Falta
                </button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Exercise;
