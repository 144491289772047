import React, { useState, useEffect } from "react";
import "./App.css";
import WorkoutDay from "./components/WorkoutDay";

function App() {
  const [diaDeLaSemana, setDiaDeLaSemana] = useState('');

  useEffect(() => {
    const hoy = new Date();
    const dia = hoy.getDay();

    switch(dia) {
      case 1:
        setDiaDeLaSemana('Lunes');
        break;
      case 2:
        setDiaDeLaSemana('Martes');
        break;
      case 4:
        setDiaDeLaSemana('Jueves');
        break;
      case 5:
        setDiaDeLaSemana('Viernes');
        break;
      default:
        setDiaDeLaSemana(null);
    }
  }, []);
  const exercises = {
    Lunes: {
      emom: [
        { name: "Levantar pesa con antebrazo Izquierdo", duration: 30 },
        { name: "Levantar pesa con antebrazo Derecho", duration: 30 },
        { name: "Subir piernas a 90° Izquierda", duration: 30 },
        { name: "Subir piernas a 90° Derecha", duration: 30 },
        { name: "Flexiones de brazos apoyando la rodilla y aplaudiendo arriba", repetitions: 10 },
        { name: "Panza abajo, manteniendo brazos y piernas arriba", duration: 20 },
        { name: "Peso muerto con una pierna apoyada", repetitions: 10 },
        { name: "Peso muerto con la otra pierna apoyada", repetitions: 10 },
        { name: "Levantar pesa con antebrazo Izquierdo", duration: 30 },
        { name: "Levantar pesa con antebrazo Derecho", duration: 30 },
        { name: "Subir piernas a 90° Izquierda", duration: 30 },
        { name: "Subir piernas a 90° Derecha", duration: 30 },
        { name: "Flexiones de brazos apoyando la rodilla y aplaudiendo arriba", repetitions: 10 },
        { name: "Panza abajo, manteniendo brazos y piernas arriba", duration: 20 },
        { name: "Peso muerto con una pierna apoyada", repetitions: 10 },
        { name: "Peso muerto con la otra pierna apoyada", repetitions: 10 },
      ],
      amrap: [
        { name: "Flexiones de brazos apoyando la rodilla y aplaudiendo arriba", repetitions: 15 },
        { name: "Subir piernas a 90°", repetitions: 12 },
        { name: "Burpees con saltos", repetitions: 10 },
        { name: "Elevaciones de cadera", repetitions: 30 },
      ],
    },
    Martes: {
      timed: [
        { name: "Levantar pesa con antebrazo Izquierdo", duration: 30 },
        { name: "Levantar pesa con antebrazo Derecho", duration: 30 },
        { name: "Sentadillas con pie apoyado a banco o silla Izquierda", repetitions: 10 },
        { name: "Sentadillas con pie apoyado a banco o silla Derecha", repetitions: 10 },
        { name: "Peso muerto con una pierna apoyada", repetitions: 10 },
        { name: "Peso muerto con la otra pierna apoyada", repetitions: 10 },
        { name: "Estocadas Izquierda", repetitions: 10 },
        { name: "Estocadas Derecha", repetitions: 10 },
        { name: "Flexiones de brazos explosivas en diamante", repetitions: 10 },
      ],
      emom: [
        { name: "Burpees con saltos", repetitions: 10 },
        { name: "Subir piernas a 90°", repetitions: 15 },
        { name: "Peso muerto con una pierna apoyada", repetitions: 10 },
        { name: "Peso muerto con la otra pierna apoyada", repetitions: 10 },
        { name: "Elevación de talón", repetitions: 30 },
        { name: "Flexiones de brazos inclinado", repetitions: 10 },
        { name: "Peso muerto con una pierna apoyada", repetitions: 10 },
        { name: "Burpees con saltos", repetitions: 10 },
        { name: "Flexiones de brazos inclinado", repetitions: 10 },
      ],
    },
  };
  return (
    <section id="exercisesAll">
      <h1>Plan de Ejercicios Semanal</h1>
      <WorkoutDay day={diaDeLaSemana} exercises={exercises[diaDeLaSemana]} />
    </section>
  );
}

export default App;
