import React, { useState, useEffect } from "react";

const TimedWorkout = ({ minutes }) => {
  const totalSeconds = minutes * 60;
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [roundsCompleted, setRoundsCompleted] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isCountingComplete, setIsCountingComplete] = useState(false);

  useEffect(() => {
    let timerId;
    if (!isPaused && secondsElapsed < totalSeconds) {
      timerId = setTimeout(() => {
        setSecondsElapsed(secondsElapsed + 1);
      }, 1000);
    } else if (secondsElapsed === totalSeconds) {
      setIsCountingComplete(true);
    }
    return () => clearTimeout(timerId);
  }, [secondsElapsed, isPaused, totalSeconds]);

  const incrementRounds = () => {
    if (!isPaused && !isCountingComplete) {
      setRoundsCompleted(roundsCompleted + 1);
    }
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const resetTimer = () => {
    setIsPaused(false);
    setRoundsCompleted(0);
    setSecondsElapsed(0);
    setIsCountingComplete(false);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div id="for_time">
      <h3>Por Tiempo</h3>
      <div className="timer_for_time">
        {formatTime(secondsElapsed)}
        <p>Rondas Completadas: {roundsCompleted}</p>
      </div>
      <button
        onClick={incrementRounds}
        disabled={isPaused || isCountingComplete}
      >
        Completar Ronda
      </button>
      <button onClick={togglePause}>{isPaused ? "Reanudar" : "Pausar"}</button>
      <button onClick={resetTimer} disabled={!isCountingComplete}>
        Reiniciar Conteo
      </button>
    </div>
  );
};

export default TimedWorkout;
