import React, { useState, useEffect } from "react";

const AMRAP = ({ minutes }) => {
  const [secondsLeft, setSecondsLeft] = useState(minutes * 60);
  const [roundsCompleted, setRoundsCompleted] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let timerId;

    if (!isPaused && secondsLeft > 0) {
      timerId = setTimeout(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    }

    return () => clearTimeout(timerId);
  }, [secondsLeft, isPaused]);

  const incrementRounds = () => {
    if (!isPaused) {
      setRoundsCompleted(roundsCompleted + 1);
    }
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div id="amrap">
      <h3>AMRAP</h3>
      {console.log(secondsLeft)}
      <div className="timer_amrap">
        {secondsLeft < 600
          ? `0${formatTime(secondsLeft)}`
          : formatTime(secondsLeft)}
        <p>Rounds Completed: {roundsCompleted}</p>
      </div>
      <button onClick={togglePause}>{isPaused ? "Resume" : "Pause"}</button>
      <button onClick={incrementRounds} disabled={isPaused}>
        Complete Round
      </button>
    </div>
  );
};

export default AMRAP;
